@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* 16px is the Tailwind default */
  /* Change this value to make everything in the app bigger or smaller */
  font-size: 16px;
}

/* Hide number input arrows/spinner */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
